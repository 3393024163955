import React from 'react'
import { graphql } from 'gatsby'
import random from 'lodash/random'

import Hero from '../components/hero'
import Post from '../components/postItems'
import MainContent from '../components/mainContent'
import Layout from '../components/layout'

const words = ['developer', 'trainer', 'traveller']

const IndexPage = props => (
  <Layout location={props.location}>
    <Hero
      backgroundImageSrc={
        props.data.contentfulAsset && props.data.contentfulAsset.fluid.src
      }
    >
      <h1 className='large-text tag-name'>
        Thoughts, stories and ideas from a {words[random(words.length - 1)]}.
      </h1>
    </Hero>
    <MainContent>
      <Post.List>
        {props.data.allContentfulPost.edges.map(({ node: post }) => (
          <Post.ListItem key={post.id} post={post} />
        ))}
      </Post.List>
    </MainContent>
  </Layout>
)

export const pageQuery = graphql`
  query HomePosts {
    allContentfulPost(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            fluid(
              maxWidth: 700
              maxHeight: 394
              quality: 85
              resizingBehavior: FILL
              cropFocus: BOTTOM
            ) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
            }
          }
          createdAt
          author {
            name
            slug
          }
          tags {
            id
            name: title
            slug
          }
        }
      }
    }
    contentfulAsset(title: { eq: "michel-catalisano-421511-unsplash" }) {
      ...HeroBackgroundImage
    }
  }
`

export default IndexPage
