import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import { AuthorLink, PostLink, TagLink } from '../linkHelper'
import { formatDate } from '../dateUtils'

const Post = {}

Post.List = props => <Row className='post-item-wrap'>{props.children}</Row>
Post.ListItem = props => (
  <Col lg='6'>
    <div className='post-item'>
      <PostLink slug={props.post.slug}>
        {props.post.featuredImage !== null ? (
          <Img
            className='featured-image'
            fluid={props.post.featuredImage.fluid}
          />
        ) : null}
      </PostLink>
      <div className='post-tag'>
        {props.post.tags.map(tag => (
          <TagLink key={tag.id} slug={tag.slug}>
            {tag.name}
          </TagLink>
        ))}
      </div>
      <h2 className='post-title h5'>
        <PostLink slug={props.post.slug}>{props.post.title}</PostLink>
      </h2>
      <div className='post-meta'>
        <span className='author'>
          <AuthorLink slug={props.post.author.slug}>
            {props.post.author.name}
          </AuthorLink>
        </span>
        <span className='date'>{formatDate(props.post.createdAt)}</span>
        <span className='reading-time'>
          {props.post.body.childMarkdownRemark.timeToRead} min read
        </span>
      </div>
    </div>
  </Col>
)

const share = (href, name, width = 580, height = 296) => event => {
  event && event.preventDefault && event.preventDefault()
  window.open(href, name, `width=${width},height=${height}`)
}

Post.Sharer = props => (
  <div className='share-wrap clearfix text-center'>
    <div className='share-text h5'>Share this post</div>
    <ul className='share-links'>
      <li>
        <a
          className='facebook'
          href={`https://www.facebook.com/sharer/sharer.php?u=${props.href}`}
          onClick={share(
            `https://www.facebook.com/sharer/sharer.php?u=${props.href}`,
            'facebook.sharer'
          )}
          title='Share on Facebook'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z' />
          </svg>
        </a>
      </li>
      <li>
        <a
          className='twitter'
          href={`https://twitter.com/share?url=${props.href}`}
          onClick={share(
            `https://twitter.com/share?url=${props.href}`,
            'twitter.sharer'
          )}
          title='Share on Twitter'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
          </svg>
        </a>
      </li>
      {/* <li>
        <a
          className="google-plus"
          href="https://plus.google.com/share?url=http://gro.gbjsolution.com/riding-bicycle-is-environment-friendly-and-good-for-health/"
          onclick="window.open(this.href, 'google-plus-share', 'width=580,height=296');return false;"
          title="Share on Google+"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          className="linkedin"
          href="http://www.linkedin.com/shareArticle?mini=true&amp;url=http://gro.gbjsolution.com/riding-bicycle-is-environment-friendly-and-good-for-health/&amp;title=Riding%20bicycle%20is%20environment%20friendly%20and%20good%20for%20health"
          onclick="window.open(this.href, 'linkedin-share', 'width=580,height=296');return false;"
          title="Share on Linkedin"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          className="pinterest"
          href="http://pinterest.com/pin/create/button/?url=http://gro.gbjsolution.com/riding-bicycle-is-environment-friendly-and-good-for-health/&amp;description=Riding%20bicycle%20is%20environment%20friendly%20and%20good%20for%20health"
          onclick="window.open(this.href, 'linkedin-share', 'width=580,height=296');return false;"
          title="Share on Pinterest"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </li> */}
    </ul>
  </div>
)

Post.AboutAuthor = ({ name, slug, picture, bio, socialLinks }) => (
  <div className='about-author clearfix'>
    <div className='row'>
      <div className='col-md-auto'>
        <AuthorLink slug={slug} title={name}>
          <Img fixed={picture.fixed} className='avatar' alt='author' />
        </AuthorLink>
      </div>
      <div className='col'>
        <div className='details'>
          <h3 className='author h5'>
            <AuthorLink slug={slug} title={name}>
              {name}
            </AuthorLink>
          </h3>
          <div
            className='bio'
            dangerouslySetInnerHTML={{ __html: bio.childMarkdownRemark.html }}
          />
          <ul className='meta-info'>
            {socialLinks.map(socialLink => (
              <li key={socialLink.id}>
                <a
                  href={socialLink.href}
                  title={socialLink.title}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {socialLink.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export const AboutAuthor = graphql`
  fragment AboutAuthor on ContentfulAuthor {
    name
    slug
    picture {
      fixed(quality: 85, width: 100, height: 100, cropFocus: FACES) {
        ...GatsbyContentfulFixed
      }
    }
    bio {
      childMarkdownRemark {
        html
      }
    }
    socialLinks {
      id
      title
      text
      href: url
    }
  }
`

Post.PrevNext = props => (
  <div className='prev-next-wrap'>
    <div className='row'>
      <div className='col-lg-6 col-md-12 post-wrap'>
        <div className='post'>
          <div className='prev-next-link text-center'>
            <a
              className=''
              href='/what-is-the-benefit-of-modern-day-work-culture/'
            >
              Previous Post
            </a>
          </div>
          <a href='/what-is-the-benefit-of-modern-day-work-culture/'>
            <div
              className='featured-image'
              style={{
                backgroundImage: 'url(/content/images/2018/07/034.jpg)'
              }}
            />
          </a>
          <div className='post-body-wrap'>
            <div className='post-body'>
              <div className='post-tag'>
                <a href='/tag/lifestyle/'>Lifestyle</a>
              </div>
              <h2 className='title h6'>
                <a href='/what-is-the-benefit-of-modern-day-work-culture/'>
                  What is the benefit of modern day work culture
                </a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Post.Commenter = props => (
  <div className='comment-container'>
    <div id='disqus_thread' style={{ display: 'inline-block' }} />
  </div>
)

export default Post
