import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

// import MouseScrollIndicator from '../components/mouseScrollIndicator'
// style="background-image: url(/content/images/2018/07/046.jpg);"

export default props => {
  const classNames = ['hero-area']
  const styles = {}

  if (props.backgroundImageSrc) {
    styles.backgroundImage = `url(${props.backgroundImageSrc})`
    classNames.push('has-cover')
  }

  if (props.className) {
    classNames.push(props.className)
  }

  return (
    <section className={classNames.join(' ')} style={styles}>
      <div className='hero-content-wrap h-100'>
        <Container className='h-100'>
          <Row className='h-100'>
            <Col className='text-center'>
              <div className='hero-content'>{props.children}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export const HeroBackgroundImageFragment = graphql`
  fragment HeroBackgroundImage on ContentfulAsset {
    fluid(
      maxWidth: 2000
      maxHeight: 1125
      quality: 85
      resizingBehavior: FILL
      cropFocus: BOTTOM
    ) {
      ...GatsbyContentfulFluid
    }
  }
`
